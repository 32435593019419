import {NgModule} from '@angular/core';
import {HttpClientModule, HttpClient, HTTP_INTERCEPTORS} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LocationStrategy, HashLocationStrategy} from '@angular/common';
import {AppRoutes} from './app.routes';

import {TranslateModule, TranslateLoader, MissingTranslationHandler, MissingTranslationHandlerParams} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import {AppComponent} from './app.component';
import {AppMenuComponent, AppSubMenuComponent} from './app.menu.component';
import {AppSideBarComponent} from './app.sidebar.component';
import {AppSideBarTabContentComponent} from './app.sidebartabcontent.component';
import {AppTopBarComponent} from './app.topbar.component';
import {AppFooterComponent} from './app.footer.component';
import {DashboardComponent} from './page/dashboard/dashboard.component';
import {NotFoundComponent} from './page/not-found/not-found.component';
import {AccessDeniedComponent} from './page/access-denied/access-denied.component';
import {ChangePasswordComponent} from './page/change-password/change-password.component';
import {ProfileComponent} from './page/profile/profile.component';
import {LoginComponent} from './page'
import {SharedModule} from './shared/shared.module'
import {CardModule} from 'primeng/card';
import { NgxPrintModule } from 'ngx-print';
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
export class MyMissingTranslationHandler implements MissingTranslationHandler {
    handle(params: MissingTranslationHandlerParams) {
        return 'Translate Tidak Ditemukan';
    }
}

@NgModule({
    imports: [
        BrowserModule,
        SharedModule.forRoot(),
        AppRoutes,
        HttpClientModule,
        BrowserAnimationsModule,
        CardModule,
        NgxPrintModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            missingTranslationHandler: {provide: MissingTranslationHandler, useClass: MyMissingTranslationHandler},
            useDefaultLang: false
        })
    ],
    declarations: [
        AppComponent,
        AppMenuComponent,
        AppSubMenuComponent,
        AppSideBarComponent,
        AppSideBarTabContentComponent,
        AppTopBarComponent,
        AppFooterComponent,
        DashboardComponent,
        NotFoundComponent,
        AccessDeniedComponent,
        ChangePasswordComponent,
        ProfileComponent,
        LoginComponent
    ],
    providers: [
        {provide: LocationStrategy, useClass: HashLocationStrategy},
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

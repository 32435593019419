import {Routes, RouterModule} from '@angular/router';
import {DashboardComponent, NotFoundComponent, AccessDeniedComponent, LoginComponent,} from './page';
import {AuthGuard, LoginGuard} from './guard';
import { NgModule } from '@angular/core';

@NgModule({
    imports: [
        RouterModule.forRoot([
          {canActivate: [AuthGuard], path: '', component: DashboardComponent},
          {canActivate: [LoginGuard], path: 'login', component: LoginComponent},
          {canActivate: [AuthGuard], path: 'user', loadChildren: './page/master/user/user.module#UserModule'},
          {canActivate:[AuthGuard] ,path: 'uom', loadChildren: './page/master/satuan/satuan.module#SatuanModule' },
          {canActivate:[AuthGuard] ,path: 'color', loadChildren: './page/master/warna/warna.module#WarnaModule' },
          {canActivate:[AuthGuard] ,path: 'size', loadChildren: './page/master/ukuran/ukuran.module#UkuranModule' },
          {canActivate:[AuthGuard] ,path: 'cabang', loadChildren: './page/master/cabang/cabang.module#CabangModule' },
          {canActivate:[AuthGuard] ,path: 'gudang', loadChildren: './page/master/gudang/gudang.module#GudangModule' },
          {canActivate:[AuthGuard] ,path: 'promo', loadChildren: './page/master/promo/promo.module#PromoModule' },
          {canActivate:[AuthGuard] ,path: 'shipping', loadChildren: './page/master/ekspedisi/ekspedisi.module#EkspedisiModule' },
          {canActivate:[AuthGuard] ,path: 'group', loadChildren: './page/master/jenis-barang/jenis-barang.module#JenisBarangModule' },
          {canActivate:[AuthGuard] ,path: 'motif', loadChildren: './page/master/motif/motif.module#MotifModule' },
          {canActivate:[AuthGuard] ,path: 'composition', loadChildren: './page/master/composition/composition.module#CompositionModule' },
          {canActivate:[AuthGuard] ,path: 'produk', loadChildren: './page/master/barang/barang.module#BarangModule' },
          {canActivate:[AuthGuard] ,path: 'member', loadChildren: './page/master/member/member.module#MemberModule' },
          {canActivate:[AuthGuard] ,path: 'pajak', loadChildren: './page/master/pajak/pajak.module#PajakModule' },

          {canActivate:[AuthGuard] ,path: 'stock-in', loadChildren: './page/persediaan/masuk-barang/masuk-barang.module#MasukBarangModule' },
          {canActivate:[AuthGuard] ,path: 'mutasi-barang', loadChildren: './page/persediaan/mutasi-barang/mutasi-barang.module#MutasiBarangModule' },
          {canActivate:[AuthGuard] ,path: 'opname', loadChildren: './page/persediaan/opname/opname.module#OpnameModule' },
          {canActivate:[AuthGuard] ,path: 'stock', loadChildren: './page/persediaan/persediaan/persediaan.module#PersediaanModule' },
          {canActivate:[AuthGuard] ,path: 'label-barcode', loadChildren: './page/persediaan/barcode/barcode.module#BarcodeModule' },
          {canActivate:[AuthGuard] ,path: 'stock-out', loadChildren: './page/persediaan/keluar-barang/keluar-barang.module#KeluarBarangModule' },
          {canActivate:[AuthGuard] ,path: 'sales-order', loadChildren: './page/transaksi/sales-order/sales-order.module#SalesOrderModule' },
          {canActivate:[AuthGuard] ,path: 'ordering', loadChildren: './page/pembelian/pesanan-pembelian/pesanan-pembelian.module#PesananPembelianModule' },
          {canActivate:[AuthGuard] ,path: 'faktur-beli', loadChildren: './page/pembelian/faktur-beli/faktur-beli.module#FakturBeliModule' },
          {canActivate:[AuthGuard] ,path: 'purchase-order', loadChildren: './page/transaksi/purchase-order/purchase-order.module#PurchaseOrderModule' },
          {canActivate:[AuthGuard] ,path: 'sales-return', loadChildren: './page/transaksi/retur-jual/retur-jual.module#ReturJualModule' },
          {canActivate:[AuthGuard] ,path: 'request-order', loadChildren: './page/transaksi/request-order/request-order.module#RequestOrderModule' },
          {canActivate:[AuthGuard] ,path: 'list-order', loadChildren: './page/transaksi/list-order/list-order.module#ListOrderModule' },
          {canActivate:[AuthGuard] ,path: 'attendance', loadChildren: './page/hrm/attendance/attendance.module#AttendanceModule' },
          {canActivate:[AuthGuard] ,path: 'time-report', loadChildren: './page/hrm/time_report/timereport.module#TimeReportModule' },
          {canActivate:[AuthGuard] ,path: 'commision', loadChildren: './page/transaksi/commision/commision.module#CommisionModule' },
          {canActivate:[AuthGuard] ,path: 'ar', loadChildren: './page/transaksi/piutang/piutang.module#PiutangModule' },

          {path: 'not-found', component: NotFoundComponent},
          {path: 'access-denied', component: AccessDeniedComponent},
          // kalo gak ada page not found
          { path: '**', redirectTo: 'not-found' }
        ])
    ],
    exports: [RouterModule]
})
export class AppRoutes {}

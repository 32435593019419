export class Config {
	static get() {
		return {
		    apiBackend2: "http://localhost:8000/",
		    apiBackend: 'https://apiservice.belikain.id/',
			page:1,
			rows:10
		}
	}
}

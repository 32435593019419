import { Component, OnInit } from '@angular/core';
import { ApiService, TranslatorService, LoaderService } from '../../service';
import { Config } from '../../guard';
import { LazyLoadEvent,ConfirmationService } from 'primeng/primeng';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  providers: [ConfirmationService]
})
export class DashboardComponent implements OnInit {
  dataTable:any[]
  loading: boolean = false
  totalRecords: number
  rows:number
  page:number
  chartFUSBI: any
  chartSBIFU: any
  listCabang: any[]
  IDCabang: any
  htmlToAdd: any
  periode: any
  dataBOCSPV: any[]
  dataBOC: any[]
  data: any
  dataPie: any
  options: any
  overlays: any[]
  constructor(private apiService: ApiService,
              private messageService: MessageService,
              private terjemah: TranslatorService,
              private loader: LoaderService) {
  }

  ngOnInit() {
    // this.cartFUSBI();
    // this.comboDataCabang();
    // this.setUpDataBOC();
    this.getDataDiagram();
    this.loadDataPie();
    this.options = {
        center: {lat: -6.971098, lng: 107.665374},
        zoom: 12
    };

  }

  getDataDiagram(){
    let labels : any[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];
    this.data = {
        labels: labels,
        datasets: [
            {
                label: 'My First dataset',
                backgroundColor: '#1376bc',
                borderColor: '#1E88E5',
                data: [65, 59, 80, 81, 56, 55, 40, 67,45,23, 56,100]
            }
        ]
    }
  }

  loadDataPie(){
    this.dataPie = {
      labels: ['A','B','C'],
      datasets: [
          {
              data: [300, 50, 100],
              backgroundColor: [
                  "#FF6384",
                  "#36A2EB",
                  "#FFCE56"
              ],
              hoverBackgroundColor: [
                  "#FF6384",
                  "#36A2EB",
                  "#FFCE56"
              ]
          }]
      };
  }

  comboDataCabang(){
    this.apiService.get(Config.get().apiBackend + 'service/transaksi/upload-data/combo-cabang').subscribe((res: any) => {
        this.listCabang = []
        res.data.forEach((data: any) => {
            this.listCabang.push({label: data.NamaCabang, value: data.IDCabang})
        })
    })
  }

  cartFUSBI() {

    this.apiService.get(Config.get().apiBackend+'service/laporan/dashboard/summary-fu-sbi-grafik').subscribe(data=>{
      this.chartFUSBI = {
        labels: ['Juni'],
        datasets: data.data
      }
    })
    // this.chartFUSBI = {
    //     labels: ['Januari'],
    //     datasets: [
    //         {
    //             label: 'Potensi',
    //             data: [65],
    //             fill: false,
    //             backgroundColor: '#79d9f1'
    //         },
    //         {
    //             label: 'Follow Up',
    //             data: [15],
    //             fill: false,
    //             backgroundColor: '#79cca6'
    //         },
    //         {
    //             label: 'Booking',
    //             data: [25],
    //             fill: false,
    //             backgroundColor: '#f3c782'
    //         },
    //         {
    //             label: 'Booking Show',
    //             data: [28],
    //             fill: false,
    //             backgroundColor: '#e89f96'
    //         },
    //         {
    //             label: 'Show',
    //             data: [28],
    //             fill: false,
    //             backgroundColor: '#e89f96'
    //         }
    //     ]
    // }

    this.chartSBIFU = {
        labels: ['Fleet','Retail',],
        datasets: [
            {
                    label: 'Potensi',
                    data: [165, 29, 40, 70, 56, 55, 40],
                    fill: false,
                    backgroundColor: '#79d9f1'
                },
                {
                    label: 'Follow Up',
                    data: [105, 89, 10, 81, 45, 55, 40],
                    fill: false,
                    backgroundColor: '#79cca6'
                },
                {
                    label: 'BOC',
                    data: [125, 259, 230, 81, 63, 55, 70],
                    fill: false,
                    backgroundColor: '#f3c782'
                },
                {
                    label: 'BOC Show',
                    data: [128, 148, 140, 199, 86, 27, 90],
                    fill: false,
                    backgroundColor: '#e89f96'
                },
                {
                    label: 'CAC',
                    data: [128, 148, 140, 199, 86, 27, 90],
                    fill: false,
                    backgroundColor: '#c34fff'
                }
        ]
    }
  }

  getData(pencarian:string,page:any,rows:any) {
    this.loading = true
    this.apiService.get(Config.get().apiBackend+'service/laporan/dashboard/summary-fu-sbi-spv').subscribe(data=>{
      this.dataTable = data.data
      this.totalRecords = data.totalRow
      this.loading = false
    })
  }

  loadDataLazy(event: LazyLoadEvent) {
    if (event.globalFilter == null) {
      if (this.periode == null) {
        this.getData('',event.first,event.rows)
      } else {
        this.getData('',event.first,event.rows)
      }
    } else {
      if (this.periode == null) {
        this.getData(event.globalFilter,event.first,event.rows)
      } else {
        this.getData(event.globalFilter,event.first,event.rows)
      }

    }
  }

  setUpDataBOC(){

    this.apiService.get(Config.get().apiBackend+'service/laporan/dashboard/summary-not-boc-spv').subscribe((res : any) =>{
      let html = `<p-tabView >`;
      let dataNotes;
      this.dataBOCSPV = res.data;
    })

    this.apiService.get(Config.get().apiBackend+'service/laporan/dashboard/summary-not-boc').subscribe((res : any) =>{
      let html = `<p-tabView >`;
      let dataNotes;
      this.dataBOC = res.data;
    })

  }

  zoomIn(map) {
      map.setZoom(map.getZoom()+1);
  }

  zoomOut(map) {
      map.setZoom(map.getZoom()-1);
  }

  clear() {
      this.overlays = [];
  }

}

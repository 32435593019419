import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';

import { AuthService, TranslatorService } from '../../service';
import { MessageService } from 'primeng/api';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css'],
	encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {
	loginForm: FormGroup;
	loading = false;
	submitted = false;
	error = '';
	bahasa: any[]
	loginGagal: string
	usernamePasswordNotNull: string
	constructor(
		private formBuilder: FormBuilder,
		private router: Router,
		private authService: AuthService,
		private translate: TranslateService,
		private terjemah: TranslatorService,
		private messageService: MessageService) {  }

	ngOnInit() {
		this.loginForm = this.formBuilder.group({
			UserName: ['', Validators.required],
			UserPassword: ['', Validators.required],
			bahasa: ['id',Validators.required],
			rememberMe: [false]
		});
		this.translate.use('id');
		this.bahasa = [
		{
			label:"Bahasa Indonesia",
			value:"id"
		},
		{
			label:"English",
			value:"en"
		}]
	}
	changeLanguage(event) {
		this.translate.use(event.value);
		localStorage.setItem('lang',event.value)
		this.terjemah.generateTranslate()
	}
	get f() { return this.loginForm.controls; }

	onSubmit() {
		this.submitted = true;
		if (this.loginForm.invalid) {
			this.messageService.add({key: 't-login', severity:'error', summary: this.terjemah.loaderSubject.kesalahan, detail:this.terjemah.loaderSubject.usernamePasswordNotNull});
			return;
		}

		this.loading = true;
		this.authService.login(this.f.UserName.value, this.f.UserPassword.value, this.f.rememberMe.value)
		.pipe(first())
		.subscribe(
			data => {
				this.router.navigate(['/']);
				this.loading = false;
			},
			error => {
				this.error = error;
				this.loading = false;
			});
	}

}
